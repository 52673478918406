import React from "react";
import getTitleId from "../helpers/getTitleId";
import "src/scss/components/_footerCta.scss";
import { LocalizedLink } from "../commonComponents";
const btnStyle = `tw-button tw-is-primary !tw-text-white !tw-bg-[#001c71] !tw-border-[#001c71] !tw-rounded-[12px] !tw-font-bold !tw-p-[14px_24px] !tw-inline-block !tw-m-0 !tw-mx-[1.5rem] !tw-mt-[22px] hover:bg-[#001964]`

const FooterCta = ({ entity, isEMS }) => {

  return (
    <div className="footer-cta-section tw-footer-cta-section tw-shadow-[1px_1px_40px_#86858529] tw-rounded-[24px_24px_0px_24px] tw-opacity-[1] tw-bg-[#E6E7E8] tw-m-auto tw-w-fit tw-mt-[52px] lg:tw-mt-0 tw-pl-[11.25%] tw-pr-[11.25%] tw-py-[28px] lg:tw-pt-[56px] lg:tw-pb-[48px] tw-text-center" id={getTitleId(entity.title)}>
      <div className="footer-cta-item tw-text-center">
        {!entity.fieldHideTitle && (
          <h3 className="title-4  tw-my-0 tw-text-center">{entity.title}</h3>
        )}
        {!!entity.fieldSubtitle && <p>{entity.fieldSubtitle}</p>}
        {entity.fieldGenericCtaBody?.value && (
        <div className="cta-body tw-cta-body dangerously-html-handle tw-text-[18px] lg:tw-text-[22px] tw-leading-[24px] lg:tw-leading-[29px] tw-text-[#222223] tw-mb-0 tw-mt-[1rem] lg:tw-mt-[8px]"
          dangerouslySetInnerHTML={{ __html: entity.fieldGenericCtaBody.value }}
        />
        )}
        {!!entity.fieldCtaUrl && (
          <LocalizedLink
            to={entity.fieldCtaUrl.url}
            className={`${
              isEMS ? "tw-text-accruent_white" : "tw-h-auto tw-whitespace-break-spaces"
            } ${btnStyle}`}
          >
            {entity.fieldCtaUrl.title}
          </LocalizedLink>
        )}
        {!!entity.fieldSecondaryCta && (
          <LocalizedLink
            to={entity.fieldSecondaryCta.url}
            className={`tw-h-auto tw-whitespace-break-spaces ${btnStyle}`}
          >
            {entity.fieldSecondaryCta.title}
          </LocalizedLink>
        )}
      </div>
    </div>
  );
};

export default FooterCta;
